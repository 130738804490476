import React from "react";
import ExitIntent from "./ExitIntent";
import { ComponentRenderHooks } from "@launchos/plugins/index.d";

export const onComponentRender = (
  hook,
  { exitPayload },
  { getComponentSettings, updateComponentSettings }
) => {
  if (hook.id === ComponentRenderHooks.PAGE_LOAD && exitPayload.length)
    return [
      <ExitIntent
        key="exit"
        payload={exitPayload}
        getComponentSettings={getComponentSettings}
        updateComponentSettings={updateComponentSettings}
      />,
    ];
};

export default ExitIntent;
