import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/index.d";
import LiveFormField from "./component";

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    (payload.type === "FormField" || payload.type === "TextInput")
  ) {
    return [LiveFormField];
  }
};
