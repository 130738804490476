import { PageProps } from "@launchos/modules/editor/index.d";

export enum BorderShadowTypes {
  BORDER,
  SHADOW,
}

export enum BorderStyles {
  NONE = "none",
  SOLID = "solid",
  DASHED = "dashed",
}

export enum ShadowStyles {
  NONE = "none",
  INSIDE = "inset",
  OUTSIDE = "outset",
}

export interface BordersShadowProps extends PageProps {
  /**
   * Whether or not to show the shadow group
   */
  hideShadow?: Boolean;

  /**
   * Triggers when the borders or shadows change in some way
   */
  onChange?: () => void;
}

export interface ShadowProps {
  settings?: unknown;
  onChange?: (payload) => void;
  hideShadowStyle?: boolean;
  hideShadowColor?: boolean;
  hideShadowOffset?: boolean;
  hideShadowHorizontalOffset?: boolean;
  hideShadowVerticalOffset?: boolean;
  hideShadowRadius?: boolean;
  hideShadowBlur?: boolean;
}

export interface BorderProps {
  settings?: unknown;
  onChange?: (payload) => void;
  hideBorderStyle?: boolean;
  hideBorderColor?: boolean;
  hideBorderThickness?: boolean;
  hideBorderRadius?: boolean;
}
