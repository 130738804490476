import { ComponentRenderHooks } from "@launchos/plugins/index.d";
import * as React from "react";
import { has } from "lodash";
import { ComponentTypes } from "../../types";
import { Helmet } from "react-helmet";
import { generateInlineStyle } from "@launchos/modules/editor/Builder/Properties/RichEditor/utilities";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

// import { Icon, IconTypes } from "../Icon";
import Icon from "../Icon";

import { ListItemTypes, ListItemProps, PluginProps } from "./types";

export const ListItem: React.FC<ListItemProps> = ({
  style,
  properties,
  icon,
  iconSource,
  children,
  iconStyle,
  contentStyle,
  containerStyle = {},
  type,
  html,
  id,
  mode,
}) => {
  // const Icon = icon;
  return (
    <li
      data-testid="WC-LISTITEM-LIVE"
      style={{
        display: type === ListItemTypes.BLOCK ? "block" : "flex",
        ...style,
        ...properties,
      }}
    >
      <Helmet>
        <style type="text/css">{generateInlineStyle({ properties, id }, EditorMode.LIVE)}</style>
      </Helmet>
      <div style={{ display: "flex", ...containerStyle }}>
        {icon && (
          <div
            style={{
              display: "inline-block",
              ...iconStyle,
              textAlign: "left",
            }}
          >
            <Icon
              type={icon}
              source={iconSource}
              style={iconStyle}
            // style={{
            //   ...(has(iconStyle, "fontSize")
            //     ? { fontSize: iconStyle.fontSize }
            //     : {}),
            // }}
            />
          </div>
        )}
        <div
          style={{
            display: "inline-block",
            ...contentStyle,
            textAlign: "left",
          }}
          {...(!children ? { dangerouslySetInnerHTML: { __html: html } } : {})}
        >
          {children && children}
        </div>
      </div>
    </li>
  );
};

const ListItemPluginComponent: React.FC<PluginProps> = ({ settings }) => {
  return <ListItem {...settings} />;
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.LISTITEM
  ) {
    return [ListItemPluginComponent];
  }
};

export default ListItem;
