import React from "react";
import { CSS } from "./CSS";
import { ComponentRenderHooks } from "@launchos/plugins/index.d";

export const onComponentRender = (hook, { cssPayload }, actions) => {
  if (hook.id === ComponentRenderHooks.PAGE_LOAD) {
    if (cssPayload !== "") return [<CSS key="css" payload={cssPayload} />];
  }
};

export default CSS;
