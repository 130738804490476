import * as React from "react";

import {
  EditorMode,
  EditorComponentProps,
} from "@launchos/modules/v2/Editor/types";
import { IAction } from "@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/index.d";

export enum OrderBumpTypes {
  INLINE,
  BLOCK,
}

export interface OrderBumpProps {
  id?: string;
  mode?: EditorMode;
  children?: React.ReactNode | string;
  style?: React.CSSProperties;
  properties?: React.CSSProperties;
  actions: IAction[];
  type?: OrderBumpTypes;
  html?: React.ReactNode | string;
  updateComponentSettings: any;
  getComponentSettings: any;
}

export interface PluginProps extends EditorComponentProps {
  settings: OrderBumpProps;
  match?: any;
}
