import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/index.d";
import * as React from "react";
import { RowProps, PluginProps } from "./types";
import { ComponentTypes } from "../../types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

export const Row: React.FC<RowProps> = ({
  style = {},
  properties = {},
  myCustomProperty = { backgroundColor: "#2096D3" },
}) => {
  return (
    <div data-testid="WC-ROW-LIVE" style={{ ...style, ...properties }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "space-between",
          justifyItems: "center",
        }}
      >
        <div style={{ padding: 20, ...myCustomProperty }}></div>
        <h2 style={{ width: "100%" }}>My New Component</h2>
        <div style={{ padding: 20, ...myCustomProperty }}></div>
      </div>
    </div>
  );
};

const RowPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <Row {...settings} properties={properties} />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.ROW
  ) {
    return [RowPlugin];
  }
};

export default Row;
