import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/index.d";
import * as React from "react";
import { DividerTypes, DividerProps, PluginProps } from "./types";
import { ComponentTypes } from "../../types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

export const Divider: React.FC<DividerProps> = ({
  style,
  properties,
  color,
  size,
  type,
  testId = "",
  children
}) => {
  if (type === DividerTypes.VERTICAL)
    return (
      <div
        data-testid={testId}
        style={{
          backgroundColor: color,
          ...properties,
          ...style,
          width: size,
          height: properties.height,
          margin: "0 auto",
          display: "flex",
        }}
      />
    );
  else
    return (
      <div
        data-testid={testId}
        style={{
          backgroundColor: color,
          height: size,
          ...style,
          ...properties,
        }}
      >{children}</div>
    );
};

const DividerPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <Divider {...settings} properties={properties} />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.DIVIDER
  ) {
    return [DividerPlugin];
  }
};

export default Divider;
