import _ from "lodash";
import actions from "./actions";
import { ActionTypes } from "@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/index.d";

export default (state = [], action) => {
  switch (action.type) {
    case ActionTypes.DO_NOTHING:
      return state;
    case ActionTypes.TRIGGER_NEXT_NODE:
      actions.triggerNextPage(action);
      return state;
    case ActionTypes.SUBMIT_FORM:
      actions.submitForm(action);
      return state;
    case ActionTypes.TRIGGER_PURCHASE:
      actions.triggerPurchase(action);
      return state;
    case ActionTypes.TRIGGER_UPSELL:
      actions.triggerUpsell(action);
      return state;
    case ActionTypes.GO_TO_URL:
      actions.goToUrl(action);
      return state;
    case ActionTypes.SCROLL_TO_SECTION:
      actions.scrollToSection(action);
      return state;
    case ActionTypes.DOWNLOAD_FILE:
      actions.downloadFile(action);
      return state;
    case ActionTypes.TRIGGER_WEBHOOK:
      actions.triggerWebHook(action);
      return state;
    case ActionTypes.ADD_TO_CART:
      actions.addToCart(action, _.get(state, "formContent", []));
      return state;
    case ActionTypes.REMOVE_FROM_CART:
      actions.removeFromCart(action);
      return state;
    case ActionTypes.TRIGGER_BOLTPAY:
      actions.triggerBoltPay(action);
      return state;
    case ActionTypes.SHOW_AND_HIDE:
      actions.showAndHide(action);
      return state;
    case ActionTypes.OPEN_POPUP:
      actions.openPopup(action);
      return state;
    case ActionTypes.HIDE_POPUP:
      actions.hidePopup(action);
      return state;
    case ActionTypes.CREATE_TAG:
      actions.createTag(action);
      return state;
    case ActionTypes.TOGGLE_ADD_REMOVE_TO_CART:
      actions.toggleAddRemoveCart(action);
    case ActionTypes.SWITCH_TABS:
      actions.switchTabs(action);
    case ActionTypes.ADD_TO_CALENDAR:
      actions.addToCalendar(action);
    default:
      return state;
  }
};
