import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/index.d";
import * as React from "react";
import { ComponentTypes } from "../../types";
import { SpacerProps, PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

export const Spacer: React.FC<SpacerProps> = ({ style, properties }) => {
  return (
    <div data-testid="WC-SPACER-LIVE" style={{ ...style, ...properties }} />
  );
};

const SpacerPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <Spacer {...settings} properties={properties} />{" "}
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.SPACER
  ) {
    return [SpacerPlugin];
  }
};

export default Spacer;
