import React from "react";
import { Helmet } from "react-helmet";
import { generateInlineStyle } from "@launchos/modules/editor/Builder/Properties/RichEditor/utilities";
import { EditorMode } from "@launchos/modules/v2/Editor/types";
import { parseValue } from '@launchos/modules/editor/Builder/Page/scripts'


import style from "./style";

const HeadlineContent = props => {
  const { settings, pageId } = props;
  const padding = settings.properties ? settings.properties.padding : 0;

  return (
    <p style={{ padding }}>
      <Helmet>
        <style type="text/css">{generateInlineStyle(settings, EditorMode.LIVE)}</style>
      </Helmet>
      <div
        style={{
          display: "inline",
          ...style.content,
          ...settings.properties,
          marginTop: 0,
          padding: 0
          // lineHeight: 0,
        }}
      // dangerouslySetInnerHTML={{
      //   __html: parseValue(settings.html) || "Enter Your Headline Here"
      // }}
      // dangerouslySetInnerHTML={{ __html: "This is a quick test!" }}
      >
        {/* {parseValue(settings.html)} */}
        {/* {process.env.NODE_ENV === `production` ? `<%= test['123'] %>` : <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: "This is a quick test" }} />} */}
        {typeof window === "function" === "undefined" ? `<%= item['${pageId}']['${settings.id}']['html'] %>` : <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: settings.html }} />}
      </div>
    </p>
  );
}; // it's not passing the values properly here on gatsby live build!

const Headline = props => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <HeadlineContent {...props} />
    </div>
  );
};

export default Headline;
