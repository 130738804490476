import React from "react";
import { get, has } from "lodash";
import WebFont from "webfontloader";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";

import Helmet from "react-helmet";

import Page from "@launchos/modules/editor/Builder/Page";
import PageScripts from "@launchos/plugins/addons/PageScripts";

import reducers from "./reducers";

import { IContent } from "./types";

const Site: React.FC = () => {
  const [content, setContent] = React.useState<IContent[]>([]);
  const [trackingScripts, setTrackingScripts] = React.useState("{}");
  // const [fontList, setFontList] = React.useState<string[]>([]);
  const ztx = atob;

  const fetchContent = async () => {
    const localSubdomain = `cWEtdGVzdC1reWxlLTA1LnRlbm1pbnV0ZWZ1bm5lbHMuY29t`;
    const host =
      window.location.hostname === "localhost" ?
        `sandcastlesites.s3-website-us-east-1.amazonaws.com/${ztx(localSubdomain)}`
        : window.location.hostname;

    // console.log({ localSubdomain, host });
    const protocol = window.location.protocol;
    const url = `${protocol}//${host}/content.json`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Origin: window.location.origin,
      },
    });
    const result = await response.json();
    const pages = get(result, "pages", "[]");
    const scripts = JSON.parse(unescape(get(result, "scripts", "{}")));
    const fonts = get(result, "fonts", []);

    // console.log({ host, pages, scripts, fonts });
    setContent(pages);
    setTrackingScripts(scripts);

    if (fonts.length) WebFont.load({ google: { families: fonts } });
  };

  React.useEffect(() => {
    fetchContent();
  }, []);

  return (
    <Provider store={createStore(reducers)}>
      <Router>
        {(has(trackingScripts, "body")) && (<PageScripts alreadyParsed payload={trackingScripts} type="body" />)}
        <div id="bodyTop" />
        <Helmet>
          <meta id="headBottom" />
        </Helmet>
        {(has(trackingScripts, "head")) && (<PageScripts alreadyParsed payload={trackingScripts} type="head" />)}

        {content.length ? (
          <Switch>
            {/* Create Home Page */}
            {content
              .filter(({ isHomePage }) => isHomePage)
              .map((page, key) => (
                <Route
                  key={key}
                  exact
                  path="/"
                  render={() => <Page {...page} />}
                />
              ))}
            {/* Create All Pages */}
            {content.map((page, key) => {
              return (
                <Route
                  key={key}
                  exact
                  path={`/${page.slug}`}
                  render={() => <Page {...page} />}
                />
              )
            })}
            {/* Create 404 Page */}
            {content
              .filter(({ is404 }) => is404)
              .map((page, key) => (
                <Route key={key} render={() => <Page {...page} />} />
              ))}

          </Switch>
        ) : (
          <div /> // Loading...
        )}

        {(has(trackingScripts, "bodyEnd")) && (<PageScripts alreadyParsed payload={trackingScripts} type="bodyEnd" />)}
        <div id="bodyBottom" />
      </Router>
    </Provider>
  );
};

export default Site;
