import { ComponentRenderHooks } from "@launchos/plugins/index.d";
import * as React from "react";
import { get, has } from "lodash";
// import ReactJson from "react-json-view";
import numeral from "numeral";

// import { Table } from "..";
import Table from "../Table"

import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import {
  PluginProps,
  OrderSummaryProps,
  OrderSummaryTypes,
  // OrderSummaryItemTypes,
  OrderSummaryDisplayOptions
} from "./types";

import { ComponentTypes } from "../../types";

export const getCellStyle = (properties) => {
  return {
    ...has(properties, 'fontFamily') ? { fontFamily: properties.fontFamily } : {},
    ...has(properties, 'fontSize') ? { fontSize: properties.fontSize } : {},
    ...has(properties, 'color') ? { color: properties.color } : {},
    ...has(properties, 'padding') ? { padding: properties.padding } : {}
  }
}
export const getFooterCellStyle = properties => ({ ...getCellStyle(properties), fontWeight: 'bold' });

export const OrderSummary: React.FC<OrderSummaryProps> = ({
  cart = [],
  // style = {},
  properties = {},
  cellStyle,
  footerCellStyle,
  type = OrderSummaryTypes.STANDARD,
  showHeader = true,
  showCart = true,
  showSubtotal = true,
  showTotal = true,
}) => {
  const total = cart.reduce((acc, cv) => {
    // console.log({ acc, cv });
    // console.log(cv.value, !isNaN(cv.value))
    const num = !isNaN(cv.value) ? cv.value : 0;
    return Number(acc) + Number(num);
  }, 0);

  // Psuedocode for calculating values
  // All products added up
  // - Discounts ($)
  // - Discount (%)
  // - Promo Codes ($)
  // - Promo Codes (%)
  // + Shipping
  // + Sales Tax

  // const subTotal = cart.filter(itm => itm.itemType === OrderSummaryItemTypes.PRODUCT).reduce((acc, cv) => acc + (cv.value as number), 0)
  // const dollarBasedDiscounts = cart.filter(itm => itm.itemType === OrderSummaryItemTypes.DISCOUNT && (itm.value as string).indexOf('%') === -1).reduce((acc, cv) => acc + numeral(cv.value), 0)
  // const percentBasedDiscounts = get(head(cart.filter(itm => itm.itemType === OrderSummaryItemTypes.DISCOUNT && (itm.value as string).indexOf('%') > -1)), 'value', 1)
  // const shippingTotals = cart.filter(itm => itm.itemType === OrderSummaryItemTypes.SHIPPING).reduce((acc, cv) => acc + numeral(cv.value), 0)
  // const salesTax = get(head(cart.filter(itm => itm.itemType === OrderSummaryItemTypes.SALESTAX && (itm.value as string).indexOf('%') > -1)), 'value', 1)

  return (
    <div data-testid="WC-ORDERSUMMARY-LIVE" style={{ ...properties, padding: 0 }}>
      {/* <ReactJson src={{ subTotal, dollarBasedDiscounts, percentBasedDiscounts, shippingTotals, salesTax }} /> */}
      <Table
        fields={["Item", "Price"]}
        showHeader={showHeader}
        cellStyle={cellStyle}
        footerCellStyle={footerCellStyle}
        data={[
          ...(showCart
            ? cart.map((itm) => [
              itm.title,
              numeral(itm.value).format("$0,0.00"),
            ])
            : []),
          ...(showSubtotal
            ? [["Subtotal", numeral(total).format("$0,0.00")]]
            : []),
        ]}
        footer={[
          ...(showTotal
            ? [`Due Today:`, numeral(total).format("$0,0.00")]
            : []),
        ]}
      />
    </div>
  );
};

const OrderSummaryPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  const { data, displayOptions } = settings;
  return (
    <V1ObjectWrapper settings={settings}>
      <OrderSummary
        {...settings}
        properties={properties}
        cellStyle={getCellStyle(properties)}
        footerCellStyle={getFooterCellStyle(properties)}
        cart={data.map((itm) => ({
          ...itm,
          title: get(itm, "caption", itm.title),
        }))}
        showHeader={get(
          displayOptions,
          OrderSummaryDisplayOptions.HEADER,
          false
        )}
        showSubtotal={get(
          displayOptions,
          OrderSummaryDisplayOptions.SUBTOTAL,
          false
        )}
        showTotal={get(
          displayOptions,
          OrderSummaryDisplayOptions.TOTAL,
          false
        )}
        showCart={get(
          displayOptions,
          OrderSummaryDisplayOptions.PRODUCTS,
          false
        )}
      />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.ORDERSUMMARY
  ) {
    return [OrderSummaryPlugin];
  }
};

export default OrderSummary;
