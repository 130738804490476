import {
  ComponentRenderHooks,
  ListItemsHooks,
} from "@launchos/plugins/index.d";
import LiveColumn from "./component";
import WebComponentWrapper from "./WebComponentWrapper";

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === "Column"
  ) {
    return [LiveColumn];
  }
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT_WRAPPER &&
    payload.type === "Column"
  ) {
    return [WebComponentWrapper];
  }
};
