import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { ContainerProps, ContainerContentProps } from "./index.d";
import { ShadowStyles } from "@launchos/modules/editor/Builder/Properties/Sections/BorderShadow/index.d";

import { CURSOR_ID } from "@launchos/modules/editor/Builder/WebComponent/types";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

export const ContainerContent: React.FC<ContainerContentProps> = (props) => {
  const { settings, pageContent, mode = EditorMode.LIVE, EmptyState } = props;

  const childContent = pageContent.filter((itm) => itm.parent === settings.id);

  const isEmpty = childContent.length === 0;
  const isEmptyWithCursor =
    childContent.length === 1 && childContent[0].id === CURSOR_ID;

  const style = props.properties;

  const defaultStyle: React.CSSProperties = {
    padding: get(settings.properties, "padding", "inherit"),
    height: get(settings.properties, "height", "inherit"),
  };

  const containerContentStyle: React.CSSProperties = {
    ...style,
    backgroundImage: get(settings.properties, "backgroundImage", "inherit"),
    backgroundSize: get(settings.properties, "backgroundSize", "inherit"),
    backgroundPosition: get(
      settings.properties,
      "backgroundPosition",
      "inherit"
    ),
    backgroundRepeat: get(settings.properties, "backgroundRepeat", "inherit"),
    borderWidth: get(settings.properties, "borderWidth", "inherit"),
    borderStyle: get(settings.properties, "borderStyle", "inherit"),
    borderRadius: get(settings.properties, "borderRadius", "inherit"),
    borderColor: get(settings.properties, "borderColor", "inherit"),
    ...(get(settings.properties, "shadowStyle") === ShadowStyles.OUTSIDE
      ? {
        boxShadow: get(settings.properties, "boxShadow", "inherit"),
      }
      : {}),
    overflow: "hidden",
  };

  const bW: number = Number(String(containerContentStyle.borderWidth).replace("rem", ""))

  const style2: React.CSSProperties = {
    backgroundImage: `none`,
    ...(get(settings.properties, "shadowStyle") === ShadowStyles.INSIDE
      ? {
        boxShadow: get(settings.properties, "boxShadow", "inherit"),
      }
      : {}),
    backgroundColor: get(settings.properties, "backgroundColor"),
    // if a border exists (borderWidth > 0), do nothing (let the border radius apply to the parent div)
    ...(bW > 0) ? {} : { borderRadius: get(settings.properties, "borderRadius", "inherit") },
    ...defaultStyle,
  };

  return (
    <div style={containerContentStyle}>
      <div style={style2}>
        {(isEmpty || isEmptyWithCursor) && mode === EditorMode.EDITOR ? (
          <EmptyState {...props} />
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};

const Container: React.FC<ContainerProps> = (props) => {
  const handleClick = () => {
    const {
      dispatch,
      settings,
      cKey,
      fieldValues,
      updateComponentSettings,
      getComponentSettings,
    } = props;
    const { actions } = settings;

    if (actions) {
      const doTheseActions = actions.filter((itm) => itm.behavior === "click");
      doTheseActions.forEach((action) =>
        dispatch({
          ...action,
          settings: { ...settings, cKey, fieldValues },
          updateComponentSettings,
          getComponentSettings,
        })
      );
    }
  };

  const properties = props.settings.properties;
  const marginTop = properties.marginTop || -1; // temporarily fixes this problem: https://ambid.slack.com/archives/G164PAK7D/p1560526689091500
  const defaultPosition = marginTop < 0 ? "relative" : "inherit"; // this lets objects with a negative marginTop overlap nearby objects
  const position = get(properties, "position", defaultPosition);
  const width = get(properties, "width");
  const { actions = false } = props.settings;

  const containerContentStyle: React.CSSProperties = {
    padding: get(properties, "padding"),
    backgroundColor: get(properties, "backgroundColor", "inherit"),
    backgroundImage: get(properties, "backgroundImage", "inherit"),
    backgroundSize: get(properties, "backgroundSize", "inherit"),
    backgroundPosition: get(properties, "backgroundPosition", "inherit"),
    backgroundRepeat: get(properties, "backgroundRepeat", "inherit"),
    borderWidth: get(properties, "borderWidth", "inherit"),
    borderStyle: get(properties, "borderStyle", "inherit"),
    borderColor: get(properties, "borderColor", "inherit"),
    boxShadow: get(properties, "boxShadow", "inherit"),
    shadowColor: get(properties, "shadowColor", "inherit"),
    shadowOffset: get(properties, "shadowOffset", "inherit"),
    shadowRadius: get(properties, "shadowRadius", "inherit"),
  };

  return (
    <div
      style={{
        justifyContent: "normal",
        display: "block",
        padding: "1px 0 0 0", // temporarily fixes this problem: https://ambid.slack.com/archives/G164PAK7D/p1560526689091500
        height: properties.height || "inherit",
        width,
        marginTop,
        position,
        cursor: actions ? "pointer" : "inherit",
      }}
      onClick={handleClick}
    >
      <ContainerContent
        {...props}
        style={containerContentStyle}
        mode={EditorMode.LIVE}
      />
    </div>
  );
};

export default connect()(Container);
