import * as React from "react";
import Stats from "./Stats";
import { ComponentRenderHooks } from "@launchos/plugins/index.d";

export const onComponentRender = (hook, { pageId = "", userId = "", campaignId = "" }) => {
  if (hook.id === ComponentRenderHooks.PAGE_LOAD && pageId.length)
    return [<Stats key="stats" pageId={pageId} userId={userId} campaignId={campaignId} siteId="" />];
};

export default Stats;
