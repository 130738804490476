import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { generateInlineStyle } from "@launchos/modules/editor/Builder/Properties/RichEditor/utilities";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

import { parseValue } from '@launchos/modules/editor/Builder/Page/scripts'

const Content = props => {
  const { settings, queryString, pageId } = props;
  const { actions } = settings;
  // console.log({ settings })

  const Parent = x => (
    <div style={{ ...settings.properties, marginTop: 0 }}>{x.children}</div>
  );
  const content = (
    <>
      <Helmet>
        <style type="text/css">{generateInlineStyle(settings, EditorMode.LIVE)}</style>
      </Helmet>
      {/* <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: parseValue(settings.html) }} /> */}
      <div
        style={{ display: "inline" }}
        className="wc-ds-html"
        data-settings-id={settings.id}
      // dangerouslySetInnerHTML={{ __html: "This is a quick test!" }}
      >
        {typeof window === "function" === "undefined" ? `<%= item['${pageId}']['${settings.id}']['html'] %>` : <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: parseValue(settings.html) }} />}
      </div>
    </>
  );

  const haslink = _.get(
    _.filter(
      actions,
      itm =>
        itm.behavior === "click" &&
        (itm.type === "GO_TO_URL" || itm.type === "TRIGGER_NEXT_NODE")
    ),
    0,
    false
  );

  let href = haslink
    ? _.get(actions, "[0].payload.url", _.get(settings, "href", ""))
    : "";

  href = _.has(props, "queryString") ? `${href}?id=${queryString.id}` : href;

  return haslink ? (
    <Parent>
      <a href={href} style={{ cursor: "pointer", textDecoration: "underline" }}>
        {content}
      </a>
    </Parent>
  ) : (
    <Parent>{content}</Parent>
  );
};

const Paragraph = props => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  const handleClick = () => {
    const {
      dispatch,
      settings,
      cKey,
      fieldValues,
      updateComponentSettings,
      getComponentSettings
    } = props;
    const { actions } = settings;

    if (actions) {
      const doTheseActions = actions.filter(itm => itm.behavior === "click");
      doTheseActions.forEach(action =>
        dispatch({
          ...action,
          settings: { ...settings, cKey, fieldValues },
          updateComponentSettings,
          getComponentSettings
        })
      );
    }
  };

  return (
    <div onClick={handleClick} style={{ marginTop, textAlign }}>
      <Content {...props} />
    </div>
  );
};

export default connect()(Paragraph);
