import * as React from "react";
import reducers from "@launchos/plugins/webcomponents/v2/Button/reducers";
import { ActionBehaviors } from "@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/index.d";
import { EditorMode } from "@launchos/modules/v2/Editor/types";

import {
  getDimensionStyle,
  getDimensionLabelStyle,
  getDividerStyle,
} from "./style";

import { CountdownProps } from "./types";

export const RenderCountdown = (props: CountdownProps) => {

  const {
    showDays,
    showHours,
    showMinutes,
    showSeconds,
    showDivider,
    showLabels,
    style,
    labelStyle,
    dimensionStyle,
    dividerStyle,
    numberStyle,
    actions = [],
    mode = EditorMode.LIVE,
    updateComponentSettings = () => null,
    getComponentSettings = () => null,
  } = props;

  const [isExpired, setIsExpired] = React.useState<boolean>(false);

  React.useEffect(() => {
    console.log("expired!", "is live?", mode === EditorMode.LIVE, actions, isExpired)
    if (actions && mode === EditorMode.LIVE && isExpired) {
      const doTheseActions = actions.filter((itm) => itm.behavior === ActionBehaviors.CLICK);
      console.log("Expired! Doing these actions -- ", actions);
      doTheseActions.forEach((action) => {
        reducers([], {
          ...action,
          settings: props,
          updateComponentSettings,
          getComponentSettings,
        })
      });
    }
  }, [isExpired])

  const Divider = () => (
    <div
      style={{
        // ...getDimensionStyle(dimensionStyle),
        ...getDividerStyle(dividerStyle),
      }}
    >
      :
      {showLabels && (
        <p style={{ ...getDimensionLabelStyle(labelStyle) }}>&nbsp;</p>
      )}
    </div>
  );

  const Dimension = ({ caption, count }) => (
    <div style={getDimensionStyle(dimensionStyle)}>
      <div style={numberStyle}>{String(count).padStart(2, '0')}</div>
      {showLabels && (
        <p style={getDimensionLabelStyle(labelStyle)}>{caption}</p>
      )}
    </div>
  );

  return ({ hours, days, minutes, seconds, completed }) => {

    if (completed) {

      setIsExpired(true);

      return (
        <p style={getDimensionLabelStyle(labelStyle)}>Your time has expired</p>
      );
    }

    return (
      <div style={style}>
        {showDays && (
          <>
            <Dimension caption="Days" count={days} />
            {showDivider && <Divider />}
          </>
        )}
        {showHours && (
          <>
            <Dimension caption="Hours" count={hours} />
            {showDivider && <Divider />}
          </>
        )}
        {showMinutes && (
          <>
            <Dimension caption="Minutes" count={minutes} />
            {showDivider && <Divider />}
          </>
        )}
        {showSeconds && (
          <>
            <Dimension caption="Seconds" count={seconds} />
          </>
        )}
      </div>
    );
  };

};
