import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/index.d";
import * as React from "react";
import { FacebookProvider, Comments } from "react-facebook";

import { FacebookCommentsProps, PluginProps } from "./types";
import { ComponentTypes } from "../../types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

export const FacebookComments: React.FC<FacebookCommentsProps> = ({
  appId = "N/A",
  href,
  style,
  properties,
}) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  return (
    <div
      data-testid="WC-FACEBOOKCOMMENTS-LIVE"
      style={{ width: "100%", ...style, ...properties }}
    >
      {/* {appId} */}
      <FacebookProvider appId={appId}>
        <Comments
          width="100%"
          href={
            href || (typeof window === "object" ? window.location.href : null)
          }
        />
      </FacebookProvider>
    </div>
  );
};

const FacebookCommentsPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <FacebookComments {...settings} properties={properties} />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FACEBOOKCOMMENTS
  ) {
    return [FacebookCommentsPlugin];
  }
};

export default FacebookComments;
