import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import { IComponentSettings, ComponentTypes } from "../../types";

import { ISettings } from "@launchos/modules/editor/Builder/WebComponent/types";

import {
  CarouselItemType,
  CarouselItemPositions,
  ICarouselOptions,
} from "./types";
import {
  ActionBehaviors,
  ActionTypes,
} from "@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/index.d";

const id1 = Math.random().toString(36).slice(2);
const id2 = Math.random().toString(36).slice(2);

interface CarouselItemComponentSettings extends ISettings {
  itemId: string;
}

interface CarouselComponentSettings extends ISettings {
  items: CarouselItemType[];
  children: CarouselItemComponentSettings[];
  options: ICarouselOptions;
}

interface ICarouselItemComponentSettings extends IComponentSettings {
  default: CarouselComponentSettings;
}

const settings: ICarouselItemComponentSettings = {
  id: ComponentTypes.CAROUSEL,
  name: "Carousel",
  description: "",
  thumbnail: "comp_carousel.png",
  default: {
    type: ComponentTypes.CAROUSEL,
    items: [
      {
        id: id1,
        label: "Slide 1",
        active: true,
      },
      {
        id: id2,
        label: "Slide 2",
        active: false,
      },
    ],
    canHaveChildren: false,
    options: {
      arrows: true,
      dots: true,
    },
    children: [
      {
        type: ComponentTypes.CAROUSELITEM,
        canHaveChildren: true,
        preventDelete: true,
        itemId: id1,
        properties: { height: "100%", minHeight: 40 },
      },
      {
        type: ComponentTypes.CAROUSELITEM,
        canHaveChildren: true,
        preventDelete: true,
        itemId: id2,
        properties: { height: "100%", minHeight: 40 },
      },
    ],
    properties: {
      display: "inline-block",
      textAlign: "center",
      margin: 5,
      padding: 10,
      width: "100%",
    },
    actions: [
      {
        behavior: ActionBehaviors.CLICK,
        name: "Switch Carousel Item",
        payload: false,
        type: ActionTypes.SWITCH_CAROUSEL_ITEM,
      },
    ],
  },
  properties: {
    main: {
      tabs: ["Basic"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "carouselBuilder_settings",
          sectionWrap: false,
          settings: {
            label: "Carousel Builder",
            icon: "construction",
            isExpanded: true,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "carouselBuilder_options",
          sectionWrap: true,
          settings: {
            isExpanded: false,
            label: "Carousel Options",
            icon: "tune",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "carouselArrow_options",
          sectionWrap: true,
          settings: {
            isExpanded: false,
            label: "Arrow Options",
            icon: "tune",
          },
        },
        // Position & Padding
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        // Carousel item Spacing
        {
          tab: "Basic",
          type: SectionTypes.SPACING,
          sectionWrap: true,
          settings: {
            label: "Carousel Slide Spacing",
            icon: "swap_horiz",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "carouselBuilder_numOfSlides",
          sectionWrap: true,
          settings: {
            isExpanded: false,
            label: "Number of Slides",
            icon: "tune",
          },
        },
        // Carousel item Size
        {
          tab: "Basic",
          type: SectionTypes.WIDTHHEIGHT,
          settings: {
            label: "Carousel Size",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {},
        },
      ],
    },
  },
};

export default settings;
