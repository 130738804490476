import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import { IComponentSettings, ComponentTypes } from "../../types";

import { ISettings } from "@launchos/modules/editor/Builder/WebComponent/types";

import { IconTypes } from "../Icon";
import { TabItemType, TabPositions } from "./types";
import {
  ActionBehaviors,
  ActionTypes,
} from "@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/index.d";

const id1 = Math.random().toString(36).slice(2);
const id2 = Math.random().toString(36).slice(2);

interface TabComponentSettings extends ISettings {
  itemId: string;
}

interface TabsComponentSettings extends ISettings {
  items: TabItemType[];
  children: TabComponentSettings[];
  tabStyle: React.CSSProperties;
  tabActiveStyle: React.CSSProperties;
  tabHoverStyle: React.CSSProperties;
  containerStyle: React.CSSProperties;
  tabPosition: TabPositions;
  tabVisibility: boolean;
}

interface ITabComponentSettings extends IComponentSettings {
  default: TabsComponentSettings;
}

const settings: ITabComponentSettings = {
  id: ComponentTypes.TABS,
  name: "Tabs",
  description: "",
  thumbnail: "comp_tabs.png",
  default: {
    type: ComponentTypes.TABS,
    items: [
      {
        id: id1,
        label: "Tab One",
        // description: "Caption Here",
        active: true,
        // icon: IconTypes.ArrowForward,
      },
      {
        id: id2,
        label: "Tab Two",
        // description: "Caption Here",
        active: false,
        // icon: IconTypes.ArrowForward,
      },
    ],
    canHaveChildren: false,
    children: [
      {
        type: ComponentTypes.TAB,
        canHaveChildren: true,
        preventDelete: true,
        itemId: id1,
        properties: { height: "100%", minHeight: 40 },
      },
      {
        type: ComponentTypes.TAB,
        canHaveChildren: true,
        preventDelete: true,
        itemId: id2,
        properties: { height: "100%", minHeight: 40 },
      },
    ],
    tabStyle: { display: "flex" },
    tabActiveStyle: {},
    tabHoverStyle: {},
    containerStyle: {},
    tabPosition: TabPositions.TOP,
    tabVisibility: true,
    properties: {
      display: "inline-block",
      textAlign: "center",
      margin: 5,
      padding: 10,
      // backgroundColor: "#CC0000",
      width: "100%",
    },
    actions: [
      {
        behavior: ActionBehaviors.CLICK,
        name: "Switch Tabs",
        payload: false,
        type: ActionTypes.SWITCH_TABS,
      },
    ],
  },
  properties: {
    main: {
      tabs: ["Basic", "Advanced"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "tabBuilder_settings",
          sectionWrap: false,
          settings: {
            label: "Tab Builder",
            icon: "construction",
            isExpanded: true,
          },
        },
        // Tabs Color
        {
          type: SectionTypes.CUSTOM,
          tab: "Basic",
          id: "custom_color",
          sectionWrap: true,
          settings: {
            label: "Tab Color",
            icon: "colorize",
          },
        },
        // Tabs Font
        {
          type: SectionTypes.TYPOGRAPHY,
          tab: "Basic",
          settings: {},
        },
        // Position & Padding
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        // Tab Spacing
        {
          tab: "Basic",
          type: SectionTypes.SPACING,
          sectionWrap: true,
          settings: {
            label: "Tab Spacing",
            icon: "height",
          },
        },
        // Tab Size
        {
          tab: "Basic",
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Tab Size",
            text: "Width",
            icon: "swap_horiz",
          },
        },
        // Tab Alignment
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "custom_alignment",
          sectionWrap: true,
          settings: {
            label: "Tab Alignment",
            icon: "vertical_align_center",
          },
        },
        {
          tab: "Advanced",
          type: SectionTypes.CUSTOM,
          id: "custom_visibility",
          sectionWrap: true,
          settings: {
            label: "Tab Display Options",
            icon: "visibility",
          },
        },
        // Borders & Shadow (Admin)
        {
          tab: "Advanced",
          sectionWrap: true,
          type: SectionTypes.CUSTOM,
          id: "custom_border",
          settings: {
            isExpanded: true,
            label: "Tab Borders",
            icon: "rounded_corners",
          },
        },
        {
          tab: "Advanced",
          type: SectionTypes.ACTIONS,
          settings: {
            label: "Tab Action (When Clicked)",
            onlyTheseActions: [ActionTypes.DO_NOTHING, ActionTypes.SWITCH_TABS],
            children: null,
          },
        },
      ],
    },
  },
};

export default settings;
